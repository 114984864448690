import { get } from 'utils/request';

function getImgUrl(link) {
    return `https://files.jhechem.com/${link}`;
}
function getFileContent(link) {
    return get(`https://files.jhechem.com/${link}`,{},{mode: 'cors'})
}


const localStorage = {
    get: function(key) {
      return window.localStorage.getItem(key);
    },
    set: function(key, value) {
        window.localStorage.setItem(key, value);
    },
    delOne: function(key) {
        window.localStorage.removeItem(key);
    }
}
const sessionStorage = {
    get: function(key) {
      return window.sessionStorage.getItem(key);
    },
    set: function(key, value) {
        window.sessionStorage.setItem(key, value);
    },
    delOne: function(key) {
        window.sessionStorage.removeItem(key);
    }
}
export { localStorage, sessionStorage, getImgUrl, getFileContent };
