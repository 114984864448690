import React, { useContext, useEffect, useState } from 'react';
import { currentContext } from 'App';
import SwiperComponent from 'components/Swiper';
import NewsList from 'components/NewsList';
import HomeProductDetail from './components/HomeProductDetail';
import AnimationBox from './components/AnimationBox';
import { sessionStorage, getFileContent, getImgUrl } from 'utils/utils';
import ProductList from 'components/ProductList';
import Title from 'elements/Title';
import { HOME_PRODUCT_CLASSIFY } from 'pages/Product/constants.js'
import './index.less';

function Home() {
    const { cover={} } = useContext(currentContext);
    const [introductionContent,setIntroductionContent] = useState('')
    useEffect(() => {
        cover.introduction && getFileContent(cover.introduction.content).then((res)=>{
        if(res.res){
            setIntroductionContent(res.res.content)
        }
      }) 
    }, [cover.introduction])
    return (
        <div>
            {/* <div className="swiper-container">
                {
                    cover && cover.banners && cover.banners.length>1 &&<SwiperComponent data={cover.banners.map(item=>{
                        return {
                            ...item,
                           link: getImgUrl(item.link)
                        }
                    })}/>
                }
                {
                    cover && cover.banners && cover.banners.length===1 && <img className="single-class" src={getImgUrl(cover.banners[0].link)}/>
                }
            </div> */}

            <AnimationBox />

            <div className="home-content-container">
                {Object.keys(HOME_PRODUCT_CLASSIFY).map(key=>{
                    return <HomeProductDetail key={key}  type={key}/>
                })}
            </div>
            {/* <div className="card-bar">
                <a className="card-container" href={"/ProductIntroduction"}>
                    <img src="https://files.jhechem.com/20061-20201025140219690-WechatIMG518.png" />
                </a>
            </div> */}

            <div className="contact-us-bar">
                Get in touch to discuss your chemical sourcing needs
                <a className="contact-us-btn" href={"/ContactUs"}>
                    CONTACT US
                </a>
            </div>
            {/* <h1 className="container-title">
                <Title cnTitle1 = '新闻 ' cnTitle2 = '中心' enTitle1 = 'Our ' enTitle2 = ' News'/>
            </h1>
            <div className="news-container">
                <div className="news-box">
                    <NewsList list={cover.news} limit={3} type='img'/>
                </div>
                <a href="/News" className="more-btn">{sessionStorage.get('siteName')==='中文'?'了解更多':'Know more'} ></a>
            </div>
            <div className="about-us">
                <p className="about-content" dangerouslySetInnerHTML={{__html:introductionContent}}></p>
            </div> */}

            {/* <h1 className="container-title">
                <Title cnTitle1 = '合作 ' cnTitle2 = '伙伴' enTitle1 = 'Our ' enTitle2 = ' Partners'/>
            </h1>
            <div className="partners">
                <div className="partner-list">
                    {
                        cover && cover.partners && cover.partners.length>0 &&<SwiperComponent data={cover.partners.map(link=>{
                            return {link:getImgUrl(link)}
                        })} type="smooth" />
                    }
                </div>
            </div> */}
            
             {/* <div className="recent-products">
                <h1 className="container-title">
                    {sessionStorage.get('siteName')==='中文'?'热门产品':'Recent Stock Products'} 
                </h1>
                <ProductList list={cover.hotProducts} limit={8}/>
            </div> */}
        </div>
    );
}

export default Home;
