// 全局路径
// const commonUrl = 'https://127.0.0.1:3000';
const axios = require('axios');

// 解析 json
function parseJSON(response) {
    return response.data;
}

// 检查请求状态
function checkStatus(response) {
    if (response.status >= 200 && response.status < 500) {
        return response;
    }
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
}

function getParamUrl(url, params) {
    let str = '';
  
    if (typeof params === 'object' && params && url) {
      str += url.includes('?') ? '&' : '?';
      Object.keys(params).forEach(function (val, index) {
        if (params[val] !== null && params[val] !== undefined)
          str += (index === 0 ? '' : '&') + val + '=' + encodeURIComponent(params[val]);
      })
  
      return url + str;
    }
  }

// fetch 请求封装
export default function request(url, options = {}) {
    let { data } = options;
    if(options.method === 'GET'){
        if (options.param) {
            url = getParamUrl(url, options.param)
        }
        return axios.get(url)
            .then(checkStatus)
            .then(parseJSON)
            .catch(err => err)
    }

    if(options.method === 'POST'){
        return axios.post(url, JSON.stringify(data),{
            headers:{
                "Content-Type":"application/json"
            }
        })
        .then(checkStatus)
        .then(parseJSON)
        .catch(err => err)
    }
}

export function post(url, data) {
    return request(url, {method: 'POST', data}) 
}
export function get(url, data, options) {
    return request(url, {...options,method: 'GET', param: data,},);
}
